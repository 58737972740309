import { FC } from "react";
import styles from "./index.module.scss";
import logoImage from "../../assets/image/0xVM_logo.svg";
import { handleRedirect } from "../../app/util";
import { twitterLink } from "../../app/config";

const Header: FC = () => {
  return (
    <div className={styles.body}>
      <div className={styles.container}>
        <img className={styles.logo} src={logoImage} alt="0xVM" />
        {/* <div className={styles.contact}>Contact Us</div> */}
        <div className={styles.contact}>
          <svg className={styles.twitterImg}>
            <use xlinkHref="#icon-x" />
          </svg>
          <span
            className={styles.social}
            onClick={() => {
              handleRedirect(twitterLink, "_blank");
            }}
          >
            Twitter
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
