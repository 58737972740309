import { FC, useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.scss";

import inclusivity from "../../assets/image/inclusivity.png";
import innovation from "../../assets/image/innovation.png";
import non_profile from "../../assets/image/non_profit.png";
import openness from "../../assets/image/openness.png";
import logoImage from "../../assets/image/0xA_icon.svg";
import community from "../../assets/image/community.png";
import funds from "../../assets/image/funds.png";
import vmImg from "../../assets/image/0xVM.png";

const video = require("../../assets/video/video.mp4");

const Home: FC = () => {
  const [expandIndex, setExpandIndex] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.play();

      videoElement.addEventListener("pause", () => {
        videoElement.play();
      });

      videoElement.addEventListener("ended", () => {
        videoElement.play();
      });
    }
  }, []);
  const infoCards = [
    {
      icon: non_profile,
      title: "Non Profit",
      content:
        "We are a non-profit organization based in Switzerland, Singapore.",
      styles: [styles.itemCard, styles.profie].join(" "),
    },
    {
      icon: openness,
      title: "Openness",
      content:
        "We believe that technological progress should be achieved through open collaboration and knowledge-sharing. With an embracing mindset, we continuously explore the frontiers of blockchain, committed to delivering breakthrough capabilities and performance upgrades to Bitcoin.",
      styles: [styles.itemCard, styles.openness].join(" "),
    },
    {
      icon: innovation,
      title: "Innovation",
      content: `Technology advancement never rests. We persistently push the boundaries of blockchain, striving to realize the vision of "Scalable Bitcoin without Compromise".`,
      styles: [styles.itemCard, styles.innovation].join(" "),
    },
    {
      icon: inclusivity,
      title: "Inclusivity",
      content:
        "Blockchain technology must serve all. Upholding technological democracy, we actively nurture and support the developer community, enabling more individuals to participate in the process of innovation.",
      styles: [styles.itemCard, styles.inclusivity].join(" "),
    },
  ];
  const aboutInfo = [
    {
      icon: "guild",
      title: "Mission",
      content:
        "The 0xVM Foundation will propel the thriving growth of the Bitcoin ecosystem through incubating and investing in innovative blockchain projects, cultivating top-tier technical talent, and collaborating extensively with the developer community. We are committed to achieving the vision of bringing Bitcoin's boundless potential to benefit the world, transforming Bitcoin into a blockchain infrastructure that truly serves all of humanity.",
    },
    {
      icon: "reward_tool",
      title: "Community Collaboration",
      content:
        "We firmly believe that a decentralized open-source community is the cornerstone of driving technological innovation. The 0xVM Foundation will actively participate in and support the Bitcoin developer community, providing the necessary funding, technical support, and resources. Through the collective efforts of the community, we aspire to promote Bitcoin as a more scalable, secure, and decentralized blockchain network.",
    },
    {
      icon: "rarity",
      title: "Frontier Technology",
      content: `As an investment fund focused on building Bitcoin's infrastructure, 0xVM will continue to closely monitor the latest breakthroughs in blockchain technology, including consensus mechanisms, scaling solutions, and privacy protection. We believe that relentless technological innovation is the key to ultimately realizing the vision of "Scalable Bitcoin without Compromise", transforming Bitcoin into a blockchain infrastructure that truly serves all of humanity.`,
    },
  ];
  const btnInfo = [
    {
      img: vmImg,
      title: "Supported Projects",
      content:
        "0xVM is a high-performance execution layer built on Bitcoin for scaling.",
      btnTitle: "Learn More",
      enable: true,
      onclick: () => {
        window.open("https://0xvm.com", "_blank");
      },
    },
    {
      img: funds,
      title: "Grants and Funding",
      content:
        "The 0xVM Foundation has established a dedicated fund to support innovative blockchain projects and talents.",
      btnTitle: "Explore More",
      enable: false,
      onclick: () => {},
    },
    {
      img: community,
      title: "Community",
      content:
        "Join us in shaping the future of the Bitcoin world. We actively collaborates with the ecosystem to drive continuous innovation.",
      btnTitle: "Join Now",
      enable: true,
      onclick: () => {
        window.open("https://discord.gg/Fpwt65wDvU", "_blank");
      },
    },
  ];
  return (
    <div className={styles.body}>
      <div className={styles.banner} />
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.topLeftContainer}>
            <div className={styles.topTitle}>
              Bringing <span>Bitcoin</span>'s Boundless Potential to All
            </div>
            <span className={styles.topDes}>
              The 0xVM Foundation is a non-profit foundation based in
              Switzerland, Singapore, dedicated to building an innovation hub
              that brings the boundless possibilities of the Bitcoin ecosystem
              to benefit all.
            </span>
          </div>
          <video
            className={styles.topRightContainer}
            ref={videoRef}
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={video} type="video/mp4" />
          </video>
        </div>
        <div className={styles.centerContainer}>
          {infoCards.map((_item) => {
            return (
              <div className={_item.styles} key={_item.title}>
                <img className={styles.itemImg} src={_item.icon} alt="" />
                <span className={styles.itemTitle}>{_item.title}</span>
                <span className={styles.itemDes}>{_item.content}</span>
              </div>
            );
          })}
        </div>
        <div className={styles.centerContainer}>
          <div className={styles.about}>
            <div className={styles.aboutTitleContainer}>
              <img
                className={styles.aboutImg}
                src={logoImage}
                alt="about"
              ></img>
              <span className={styles.aboutTitle}>About</span>
            </div>
            <div className={styles.aboutContent}>
              The 0xVM Foundation is dedicated to bringing Bitcoin's boundless
              potential to benefit people worldwide. We believe that Bitcoin, as
              a decentralized and censorship-resistant cryptocurrency, will play
              a crucial role in the future digital economy. To this end, we will
              continuously explore cutting-edge blockchain technologies to
              provide breakthrough computational capabilities and performance
              upgrades for Bitcoin, while supporting the development of
              innovative projects to serve global users.
            </div>
          </div>
          <div className={styles.about}>
            {aboutInfo.map((info, index) => {
              return (
                <div className={styles.aboutSub} key={index}>
                  <div
                    className={styles.aboutSubImgTitle}
                    onClick={() => {
                      if (expandIndex === index) {
                        setExpandIndex(-1);
                      } else {
                        setExpandIndex(index);
                      }
                    }}
                  >
                    <div className={styles.titleInfoContainer}>
                      <svg className={styles.subImg}>
                        <use xlinkHref={`#icon-${info.icon}`}></use>
                      </svg>
                      <span className={styles.subTitle}>{info.title}</span>
                    </div>
                    <svg className={styles.expandIcon}>
                      <use
                        xlinkHref={`#icon-${
                          expandIndex === index ? "minus" : "plus"
                        }`}
                      ></use>
                    </svg>
                  </div>
                  {expandIndex === index && (
                    <div className={styles.aboutSubContent}>{info.content}</div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.bottomContainer}>
          {btnInfo.map((_info) => {
            return (
              <div className={styles.bottomItem} key={_info.title}>
                <img
                  className={styles.bottomImg}
                  src={_info.img}
                  alt={_info.title}
                ></img>
                <div className={styles.bottomContent}>
                  <span className={styles.bottomTitle}>{_info.title}</span>
                  <span className={styles.bottomContent}>{_info.content}</span>
                </div>
                <div
                  className={[
                    styles.bottomBtn,
                    _info.enable ? "" : styles.disabled,
                  ].join(" ")}
                  onClick={_info.onclick}
                >
                  {_info.btnTitle}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;
