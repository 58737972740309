import { FC } from "react";
import styles from "./index.module.scss";

const Footer: FC = () => {
  return (
    <div className={styles.body}>
      <div className={styles.container}>
        <span
          className={styles.copyright}
        >{`© ${new Date().getFullYear()} 0xVM Foundation. `}</span>
        <span className={styles.copyright}>All rights reserved.</span>
      </div>
    </div>
  );
};

export default Footer;
